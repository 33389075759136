import * as React from "react"
import Features from "../components/features"

import Layout from "../components/layout"
import Seo from "../components/seo"

import planung from "../images/Planung.jpg"
import fertigung from "../images/Fertigung.jpg"
import montage from "../images/Montage.jpg"
import reparatur from "../images/stairs2.jpg"
import service_und_wartung from "../images/service_und_wartung.jpg"
import sarnierung_und_umbauten from "../images/sarnierung_und_umbauten.jpg"
import koordination from "../images/koordination.jpg"

const featuresList = [
  {
    title: "Planung & Beratung",
    subTitle: "Von der Planung bis zur Abnahme.",
    description: `
            Der Schlüssel zum Erfolg ist die richtige Planung deshalb hat die Planungskompetenz für uns seit jeher einen besonderen Wert. 
            Wir erarbeiten in enger Kooperation mit Architekten und Auftraggebern individuelle Lösungen und Konzepte für alle Bereiche. 
            Auf der Basis erster theoretischer Zeichnungen sind wir in der Lage mittels CAD den kompletten Bau zu Planen. 
            Den Projekterfolg ist durch unser Logistik- und Montage-Konzept garantiert. Das ist Planungsqualität nach Mass.
        `,
    imgPath: planung,
  },
  {
    title: "Fertigungen",
    subTitle: "Präzision bis ins letzte Detail.",
    description: `
            Von der Ständerbohrmaschine über eine moderne CNC-gesteuerte Doppelgehrungssäge und Biegeautomaten 
            bis hin zu 0.1 mm-genauen Profilbearbeitungszentren reicht das Maschinenportfolio. Mit Qualifizierten 
            Partnerfirmen werden alle Werkstücke hergestellt. All dass erlaubt uns eine schnelle, präzise und 
            passgenaue Arbeit abzuliefern. Alles aus einer Hand. Ein hoher Qualitätsstandard, auf den bei 
            Adoser Wert gelegt wird.
        `,
    imgPath: fertigung,
  },
  {
    title: "Montage",
    subTitle: "Beeindruckend und passgenau.",
    description: `
            Bei Adoser sorgen bestens geschulte Fachleute aus unserm eigenen Team sowie Subunternehmer für die 
            perfekte Montage aller Bauteile vor Ort. Unsere Partner transportieren auch grössere Objekte direkt 
            an Ort. So hat man bei Adoser auch vor Ort immer die Kontrolle über die qualifizierte und präzise 
            Anbringung aller gefertigten Bauteile. Der Qualitätsanspruch beginnt bei Adoser schon bei der Planung 
            und endet nicht mit der letzten Schraube bei der Montage vor Ort, sondern mit der Zufriedenheit unserer 
            Kunden.
        `,
    imgPath: montage,
  },
  {
    title: "Service & Wartung",
    subTitle: "Damit auch morgen noch alles in einem Super Zustand ist.",
    description: `
            Auch lange nach Fertigstellung Ihres Projektes sorgt das Adoser-Serviceteam für eine tadellose Funktion 
            und Ästhetik von Fenstern, Türen, Toren, Feststellanlagen und Automatentüren. Sollte tatsächlich einmal 
            ein Schaden entstehen, sorgen unsere qualifizierten Servicetechniker für eine schnelle und saubere 
            Reparatur.
        `,
    imgPath: service_und_wartung,
  },
  {
    title: "Sarnierung & Umbauten",
    subTitle: "Aus alt machen wir wieder neu.",
    description: `
            Durch die Zeit, Witterungseinflüsse und Missgeschicke entstehen Spuren, welche eine Reparatur erzwingen. 
            Um Substanzen neu zu nutzen, Werte zu erhalten und Investitionen zu schützen, entwickeln wir mit unseren 
            Partnern kluge und wirtschaftlich interessante sowie ästhetisch überzeugende Lösungen. Unsere grosse 
            Erfahrung in Renovationen, Umbauten und Sanierungen sprechen für Adoser.
        `,
    imgPath: sarnierung_und_umbauten,
  },
  {
    title: "Koordination",
    subTitle: "Viele Beteiligte jedoch nur ein Ansprechpartner.",
    description: `
            Egal ob Maurer, Tür- und Fensterbauer, Elektriker, Architekt oder Behörden, wir übernehmen für Sie die 
            Zusammenarbeit mit allen Beteiligten. Wir kennen alle geltenden Normen und Vorschriften in Bezug auf 
            Sicherheit, Brand- und Umweltschutz. Sie haben einen kompetenten Ansprechpartner im Bauwesen.
        `,
    imgPath: koordination,
  },
]

const LeistungenPage = () => (
  <Layout>
    <Seo title="Leistungen" />

    <div className="max-w-7xl mx-auto px-4 py-4 md:px-0 md:py-12">
      <h1 className="text-3xl font-extrabold text-gray-900">Leistungen</h1>
      <p className="mt-4 text-lg text-gray-500 text-left">
        Qualitätsbewusstsein und Wirtschaftliches Denken sind unser Massstab –
        individuelle Lösungen sind unsere Stärke. Wir beraten, planen,
        konstruieren, fertigen und montieren – alles aus einer Hand, nach Ihren
        Wünschen und Vorgaben. Um ein hohes Mass an Präzision zu realisieren,
        gehen Hightech-Produktion und klassisches Handwerk dabei Hand in Hand.
        Unser eigener Service- und Kundendienst sorgt dafür, dass Sie auch nach
        Jahren noch Freude an Ihren Objekten und Produkten haben. Lassen Sie
        Ihrer Kreativität freien Lauf.
        <br />
        <br />
        Unser umfangreiches Angebot reicht von der Beratung über die Planung bis
        hin zur Realisierung und sogar noch darüber hinaus. Wir zählen zu den
        Marktführern, wenn es um Fenster, Fliegenschutzgitter, Türen, Tore und
        Geländer geht. Erstklassige Leistungen, Qualität und Ihre Zufriedenheit
        sind unser Ansporn. Ihre Zufriedenheit ist unsere Visitenkarte.
      </p>
    </div>
    <Features features={featuresList} />
  </Layout>
)

export default LeistungenPage
